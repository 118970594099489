.notifications-on-toolbar {
  .list-item {
    .description {
      font-size: 0.75rem;
    }
    .sub-description {
      font-size: 0.75rem;
    }
  }
}

.notifications-page {
  .list-item {
    .description {
      font-size: 1rem;
    }
    .sub-description {
      font-size: 1rem;
    }
  }
}
