.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $primary;
  border-color: $primary;
}

.custom-checkbox .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem $primary;
}

.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

.custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
  background-color: lighten($color: $primary, $amount: 100);
}
.custom-control-label {
  vertical-align: middle;
}
