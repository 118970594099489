@import '../variables';

.pagination .page-item.active .page-link {
  background-color: $primary;
  border-color: $primary;
  color: white;
}

.pagination .page-item .page-link {
  color: $primary;
}

.pagination .page-item.disabled .page-link {
  color: $info;
}
