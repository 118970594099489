.card {
  > .table {
    border-right: 0;
    border-left: 0;
    margin-bottom: 0;

    &:last-child {
      border-bottom: 0;
    }

    &:not(.table-sm) th,
    &:not(.table-sm) td {
      padding: 0.75rem 1.25rem;
    }

    td:first-child,
    th:first-child {
      border-left: 0;
    }

    td:last-child,
    th:last-child {
      border-right: 0;
    }

    tr:first-child td,
    tr:first-child th {
      border-top: 0;
    }

    tr:last-child td {
      border-bottom: 0;
    }
  }

  .card-header + .table {
    border-top: 0;
  }
}

.table {
  color: $body-color;
}
