@import '../variables';

.tabs-vagalume {
  a {
    padding-left: 0;
    padding-right: 0;
  }

  li {
    padding-right: 1rem;
  }

  .nav-link.active {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 2px solid $primary !important;
    color: $primary !important;
    font-weight: 500;
  }

  .nav-link {
    color: $info !important;
  }
}

.notifications-dropdown-tabs {
  .tabs-vagalume {
    width: 100vw;
    .nav-tabs {
      background-color: $primary-background-light;
      border-bottom: 2px solid $primary-light;
      -webkit-box-pack: center !important;
      justify-content: center !important;
    }
    .nav {
      .nav-item {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        .nav-link.active {
          background-color: $primary-background-light;
        }
        font-size: 12px;
      }
    }
  }
}
